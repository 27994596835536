import { deAT, de, fr, enGB, it, nl, pl } from 'date-fns/locale'
import type { Locale } from 'date-fns'
import { storeToRefs } from '#imports'
import { useSelectionStore } from '~/store/selection-store'
import type { ComputedRef } from '#imports'
import type { Store } from '~/types/Store'

export const useCountryConfig = (): {
  currencySymbol: ComputedRef<'€' | '£'>
  timeZone: ComputedRef<string>
  countryCode: ComputedRef<Locale>
} => {
  const countryCodes: Record<Store['country_config']['code'], Locale> = {
    AT: deAT,
    DE: de,
    FR: fr,
    GB: enGB,
    IT: it,
    NL: nl,
    PL: pl
  }

  const selectionStore = useSelectionStore()
  const { selectedStore } = storeToRefs(selectionStore)

  const generateErrorMessage = (text: string): string => `No ${text} found in store config, Store : ${selectedStore.value?.id}`

  const currencySymbol = computed((): '€' | '£' => {
    if (!selectedStore.value?.country_config?.currency) {
      console.error(generateErrorMessage('currency'))
    }
    switch (selectedStore.value?.country_config?.currency) {
      case 'GBP':
        return '£'
      case 'EUR':
        return '€'
      default:
        return '€'
    }
  })
  const timeZone = computed((): string => {
    if (!selectedStore.value?.country_config?.timezone) {
      console.error(generateErrorMessage('time zone'))
    }
    return selectedStore.value?.country_config?.timezone ?? 'Europe/Berlin'
  })
  const countryCode = computed((): Locale => {
    const code: Store['country_config']['code'] = selectedStore.value?.country_config?.code ?? 'DE'

    if (!code) {
      console.error(generateErrorMessage('country code'))
    }
    return countryCodes[code]
  })

  return {
    currencySymbol,
    timeZone,
    countryCode
  }
}
